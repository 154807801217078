import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import colors from "../styles/color"

const FourOhFour = () => {
  return (
    <Layout>
      <SEO
        title="Page Not Found"
      />
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: colors.sand,
          color: colors.brown,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1>This Page Doesn't Exist</h1>
        <Link
          to="/"
        >
          Back to site
        </Link>
      </div>
    </Layout>
  )
};

export default FourOhFour